/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
// UI local components

import '../page-styles/index.scss';
import Seo from '../shared/Seo';

// const TrustMe = React.lazy(() => import('../page-components/Index/TrustMe'));
const PageLayout = React.lazy(() => import('../shared/PageLayout'));
const Hero = React.lazy(() => import('../page-components/Index/Hero'));
const Contact = React.lazy(() => import('../page-components/Index/Contact'));
const News = React.lazy(() => import('../page-components/Index/News'));
const Service = React.lazy(() => import('../page-components/Index/OurService'));

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function IndexPage() {
  /* ********************************** HOOKS ********************************* */

  // Localization
  const { t } = useTranslation();
  const isSSR = typeof window === 'undefined';

  /* ******************************** RENDERING ******************************* */
  return (
    <>
      <Seo title={t('title')} />
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <PageLayout>
            <div className="index">
              <Hero />
              <Service />
              <Contact />
              <News />
              {/* <TrustMe /> */}
            </div>
          </PageLayout>
        </React.Suspense>
      )}
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["HomePage", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default IndexPage;
